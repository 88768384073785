import {
    IFeature,
    IMainImage,
    useVersionsAssets,
} from "@/assets/versions.assets";

import {PREFIX} from "@/utils/imagePrefixes";

interface colors {
    haxCode: string;
    colorName: string;
    colorNameFormatted: string;
    pathSrc: string;
    colorActive: boolean;
}

export interface IVersions {
    features: IFeature[];
    src: IMainImage;
    id: number;
    mvsCode: string;
    slug: string;
    model: {
        shortname: string;
        fullname: string;
    };
    description: string;
    optionals: {
        text: string;
    }[];
    colors: colors[];
    price: number;
    path: string;
    versionCtaButton: {
        id: string;
        label: string;
        url: string;
        backgroundColor: string;
        color: string;
        download?: boolean;
        excludeArrow?: boolean;
        iconCode?: string;
    }[];
}

export const COMPRE_TITLE = "Compre a sua";
export const MONTE_TITLE = "Monte a sua";

export const links = {
    interesse: "https://www.fiat.com.br/compre/fiat-toro.html",
};

export const useCarsVersionsData = (versionNameColor?: string) => {
    const color = versionNameColor ?? "ff0000";

    const cars: IVersions[] = [
        {
            id: 1,
            mvsCode: "2261WW1",
            slug: "ranch",
            model: {
                shortname: "ranch diesel",
                fullname: "ranch diesel",
            },
            description:
                "Com seu visual fora de estrada, a Rebel possui duas motorizações (gasolina e diesel), tração 4X4 e pneus All Terrain, além de badges escurecidos.",
            optionals: [
                {text: "Capota rígida"},
                {text: "Santo Antonio integrado"},
                {text: "Central Multimedia 10,1"},
            ],
            colors: [],
            price: 0,
            path: `${PREFIX}Versions/rebel/000000.webp`,
            features: useVersionsAssets(color).ranch.features,
            src: useVersionsAssets(color).ranch.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261BP1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261BP1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
        {
            id: 0,
            mvsCode: "2261TV1",
            slug: "ultra",
            model: {
                shortname: "ultra flex",
                fullname: "ultra flex",
            },
            description:
                "",
            optionals: [
                {text: "Rodas Aro 18 pintadas de preto"},
                {text: "Acabamento interno premium"},
                {text: "Ar-condicionado Dual-Zone"},
            ],
            colors: [],
            price: 0,
            path: ``,
            features: useVersionsAssets(color).ultra.features,
            src: useVersionsAssets(color).ultra.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261TV1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261TV1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
        {
            id: 3,
            mvsCode: "2261SW1",
            slug: "volcano-diesel",
            model: {
                shortname: "volcano diesel",
                fullname: "volcano diesel",
            },
            description:
                "Com seu visual fora de estrada, a Rebel possui duas motorizações (gasolina e diesel), tração 4X4 e pneus All Terrain, além de badges escurecidos.",
            optionals: [
                {text: "Capota rígida"},
                {text: "Santo Antonio integrado"},
                {text: "Central Multimedia 10,1"},
            ],
            colors: [],
            price: 0,
            path: `${PREFIX}Versions/rebel/000000.webp`,
            features: useVersionsAssets(color).volcano.features,
            src: useVersionsAssets(color).volcano.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261ZP1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261ZP1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
        {
            id: 2,
            mvsCode: "2261SV1",
            slug: "volcano-flex",
            model: {
                shortname: "volcano flex",
                fullname: "volcano flex",
            },
            description:
                "Com seu visual fora de estrada, a Rebel possui duas motorizações (gasolina e diesel), tração 4X4 e pneus All Terrain, além de badges escurecidos.",
            optionals: [
                {text: "Capota rígida"},
                {text: "Santo Antonio integrado"},
                {text: "Central Multimedia 10,1"},
            ],
            colors: [],
            price: 0,
            path: `${PREFIX}Versions/rebel/000000.webp`,
            features: useVersionsAssets(color).volcano.features,
            src: useVersionsAssets(color).volcano.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261SV1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261SV1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
        {
            id: 4,
            mvsCode: "2261RV1",
            slug: "freedom",
            model: {
                shortname: "freedom flex",
                fullname: "freedom flex",
            },
            description:
                "Com seu visual fora de estrada, a Rebel possui duas motorizações (gasolina e diesel), tração 4X4 e pneus All Terrain, além de badges escurecidos.",
            optionals: [
                {text: "Capota rígida"},
                {text: "Santo Antonio integrado"},
                {text: "Central Multimedia 10,1"},
            ],
            colors: [],
            price: 0,
            path: `${PREFIX}Versions/rebel/000000.webp`,
            features: useVersionsAssets(color).freedom.features,
            src: useVersionsAssets(color).freedom.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261RV1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261RV1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
        {
            id: 5,
            mvsCode: "2261PV1",
            slug: "endurance",
            model: {
                shortname: "endurance flex",
                fullname: "endurance flex",
            },
            description:
                "Com seu visual fora de estrada, a Rebel possui duas motorizações (gasolina e diesel), tração 4X4 e pneus All Terrain, além de badges escurecidos.",
            optionals: [
                {text: "Capota rígida"},
                {text: "Santo Antonio integrado"},
                {text: "Central Multimedia 10,1"},
            ],
            colors: [],
            price: 0,
            path: `${PREFIX}Versions/rebel/000000.webp`,
            features: useVersionsAssets(color).endurance.features,
            src: useVersionsAssets(color).endurance.main,
            versionCtaButton: [
                {
                    id: "ctaButton-0",
                    label: MONTE_TITLE,
                    url: "https://toro.fiat.com.br/monte.html?mvs=2261PV1&year=2025#versao",
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    excludeArrow: false,
                },
                {
                    id: "ctaButton-1",
                    label: COMPRE_TITLE,
                    url: `${links.interesse}?mvs=2261PV1&year=2025`,
                    backgroundColor: "#1C1C1C",
                    color: "#FFFFFF",
                    excludeArrow: false,
                },
            ],
        },
    ];

    return cars;
};


export const defaultColors = [
    {
        "id": "84ca71b6-ff72-4184-a38d-1cac633a6a69",
        "code": "176",
        "name": "VERMELHO COLORADO",
        "description": null,
        "type": "COLOR",
        "exclusive": [],
        "inclusive": [],
        "colorCategory": "B",
        "rgb": "ff0000",
        "secondaryRgb": null,
        "hexadecimals": {
            "rgbList": null
        },
        "optionalColors": null
    },
    {
        "id": "e69340a0-4382-4b58-94f4-06bf5f4ee01d",
        "code": "345",
        "name": "AZUL JAZZ",
        "description": null,
        "type": "COLOR",
        "exclusive": [],
        "inclusive": [],
        "colorCategory": "M",
        "rgb": "11264b",
        "secondaryRgb": null,
        "hexadecimals": {
            "rgbList": null
        },
        "optionalColors": null
    },
    {
        "id": "e2cead19-74db-4cec-9b6d-0ae9e584688b",
        "code": "852",
        "name": "PRETO CARBON",
        "description": null,
        "type": "COLOR",
        "exclusive": [],
        "inclusive": [],
        "colorCategory": "M",
        "rgb": "000000",
        "secondaryRgb": null,
        "hexadecimals": {
            "rgbList": null
        },
        "optionalColors": null
    },
    {
        "id": "7424acb5-fdaa-4ad6-bf14-272bcabc7fa8",
        "code": "503",
        "name": "CINZA STING (ESPECIAL SOLIDA)",
        "description": null,
        "type": "COLOR",
        "exclusive": [],
        "inclusive": [],
        "colorCategory": "M",
        "rgb": "7d818a",
        "secondaryRgb": null,
        "hexadecimals": {
            "rgbList": null
        },
        "optionalColors": null
    },
    {
        "id": "57a70a51-151d-412e-b20d-75bf1584c8aa",
        "code": "742",
        "name": "BRANCO POLAR",
        "description": null,
        "type": "COLOR",
        "exclusive": [],
        "inclusive": [],
        "colorCategory": "P",
        "rgb": "f4f4f4",
        "secondaryRgb": null,
        "hexadecimals": {
            "rgbList": null
        },
        "optionalColors": null
    }
]

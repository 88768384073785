import { MetaType } from "@models/index";
import {
  ProjectBrand,
  ProjectInfoInterface,
  ProjectType,
} from "@models/ProjectInfo";
import { PREFIX } from "@utils/imagePrefixes";

export const PRODUCT_NAME = "Toro";
export const BRAND_NAME = "FIAT";
export const PAGE_TITLE = `${BRAND_NAME}`;

export const project: ProjectInfoInterface = {
  year: "2024",
  version: "1.0",
  debug: true,
  product: "toro",
  brand: ProjectBrand.BRAND,
  type: ProjectType.LANDING_PAGE,
  url: "https://toro.fiat.com.br",
};

export const metaTags: MetaType = {
  description:
    "Conheça a Nova Fiat Toro, ainda mais tecnológica. Picape de verdade, motor forte e potente com conforto, segurança, conectividade, bancos em couro e muito mais.",
  title: "Fiat Toro | FIAT",
  brandName: "Fiat",
  productName: "Toro",
  heading: "Fiat Toro",
  image: `${PREFIX}og-image.webp`,
  twitter: {
    card: "summary",
    site: "@FiatBR",
    imageAlt: "Fiat Toro",
    image: `${PREFIX}og-image.webp`,
  },
  facebook: {
    type: "website",
    image: `${PREFIX}og-image.webp`,
    imageAlt: "Fiat Toro",
    imageWidth: "1200",
    imageHeight: "630",
  },
  url: "https://toro.fiat.com.br/#/",
  imageAlt: "Fiat Toro",
};

export const STORAGE_PREFIX = "@digital_code/";

export const brandLinks = {
  mainUrl: "https://www.fiat.com.br",
  privacyPolicy: "https://www.fiat.com.br/politica-de-privacidade.html",
  twitter: "https://twitter.com/fiatbr",
  facebook: "https://www.facebook.com/fiatbr",
  instagram: "https://www.instagram.com/ramdobrasil/fiatbr",
  youtube: "https://www.youtube.com/c/fiatbr/featured",
};

export * as menu from "../data/menu.data";
